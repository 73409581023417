export default {
  SET_USER_INFO(state, info) {
    state.userInfo = info;
  },
  SET_LOADER(state, bool) {
    state.isLoading = bool;
  },
  SET_PARTNER_LOGO(state, logo) {
    state.partnerLogo = logo;
  },
  SET_USER_ACCOUNT_INFO(state, info) {
    state.userAccountInfo = info;
  },
  SET_LATEST_COURSES(state, courses) {
    state.latestCourses = courses.results;
  },
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  },
  SET_FETCHING_COURSES(state, isFetchingCourses) {
    state.isFetchingCourses = isFetchingCourses;
  },
  SET_PARTNER_INTEGRATION_STATUS(state, isPartnerApiIntegrated) {
    state.isPartnerApiIntegrated = isPartnerApiIntegrated;
  }
};
