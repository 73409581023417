export default {
  allConfig: state => state.config,
  getUserProfile: state => state.userProfile,
  getErrors: state => state.error,
  getSpacesList: state => state.spacesList,
  getOrgList: state => state.orgList,
  getInitiativesList: state => state.initiativesList,
  getInitiativeDetail: state => state.initiativeDetail,
  getFeaturedResourcesList: state => state.featuredResourcesList,
  getManageSectionList: state => state.manageSectionList,
  getTagsList: state => state.tags
};
