export default () => ({
  isLoading: false,
  userInfo: null,
  partnerLogo: null,
  userAccountInfo: null,
  latestCourses: [],
  currentUser: null,
  isFetchingCourses: false,
  isPartnerApiIntegrated: false
});
