<template>
  <div id="app">
    <div class="app-loader" v-if="isLoading">
      <LxpLoader />
    </div>
    <router-view />
  </div>
</template>
<script>
import { LxpLoader } from "didactica";
import { mapGetters } from "vuex";
export default {
  components: {
    LxpLoader
  },
  computed: {
    ...mapGetters(["isLoading"])
  }
};
</script>
<style lang="scss">
@import "./scss/_tooltip.scss";
html,
body {
  height: 100%;
}
#app {
  font-family: "Roboto", "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  overflow: hidden;
  .app-loader {
    min-height: 400px;
    @include flex-horizontal-center;
  }
}

.popover {
  &.b-popover {
    max-width: 360px;
    .popover-body {
      padding: 16px;
      border-radius: 4px;
    }
  }
}
</style>
