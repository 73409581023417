/* eslint-disable no-unused-vars */
import axios from "axios";

const getAuthHeader = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

function getURL(baseURL, params = {}) {
  return {
    // GET
    GET_ALL_LEARNING_SPACES: `${baseURL}/spaces/`,
    GET_SPACE_DETAIL: `${baseURL}/spaces/${params.org}/`,
    LIST_ALL_ORGANISATION: `${baseURL}/spaces/organizations/`,
    LIST_ALL_INITIATIVES: `${baseURL}/spaces/${params.org}/initiatives/`,
    GET_INITIATIVE_DETAIL: `${baseURL}/spaces/${params.org}/initiatives/${params.id}/`,
    INITIATIVES_SUMMARY: `${baseURL}/spaces/${params.org}/initiatives/summary/`,
    GET_FEATURED_COURSES: `${baseURL}/spaces/${params.org}/courses/featured/`,
    GET_MANAGE_SECTION: `${baseURL}/spaces/${params.org}/sections/`,
    GET_TAGS_LIST: `${baseURL}/tags/live/?org=${params.org}`,

    //POST
    CREATE_INITIATIVE: `${baseURL}/spaces/${params.org}/initiatives/`,
    UPDATE_FEATURED_COURSES: `${baseURL}/spaces/${params.org}/courses/featured/`,
    //DELETE
    DELETE_INITIATIVE: `${baseURL}/spaces/${params.org}/initiatives/${params.id}/`,

    // PATCH
    PUBLISH_INITIATIVE: `${baseURL}/spaces/${params.org}/initiatives/${params.id}/publish/`,
    UNPUBLISH_INITIATIVE: `${baseURL}/spaces/${params.org}/initiatives/${params.id}/unpublish/`,
    PUBLISH_SPACE: `${baseURL}/spaces/${params.org}/publish/`,
    UNPUBLISH_SPACE: `${baseURL}/spaces/${params.org}/unpublish/`
  };
}

export default {
  // GET
  getSpacesList({ commit, state }) {
    const URL = getURL(state.config.baseURL).GET_ALL_LEARNING_SPACES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      commit("SET_SPACES_LIST", response.data);
    });
  },

  listAllOrganisation({ commit, state }) {
    const URL = getURL(state.config.baseURL).LIST_ALL_ORGANISATION;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      commit("SET_ORG_LIST", response.data);
    });
  },

  listInitiatives({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).LIST_ALL_INITIATIVES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      let data = response.data;
      data.forEach(item => {
        item.org_short_name = params.org;
      });
      commit("SET_INITIATIVES_LIST", data);
    });
  },
  getInitiativeDetail({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_INITIATIVE_DETAIL;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      commit("SET_INITIATIVE_DETAIL", response.data);
    });
  },
  getFeaturedResources({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_FEATURED_COURSES;

    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      let data = response.data;
      commit("SET_FEATURED_RESOURCES_LIST", data);
    });
  },
  getManageSection({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_MANAGE_SECTION;

    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      let data = response.data;

      commit("SET_MANAGE_SECTION_LIST", data);
    });
  },
  getTagsList({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_TAGS_LIST;

    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      let data = response.data.results;

      commit("SET_TAGS_LIST", data);
    });
  },

  // POST
  createSpace({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_ALL_LEARNING_SPACES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.post(URL, params, headers).then(response => {
      dispatch("getSpacesList");
    });
  },
  createInitiative({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).CREATE_INITIATIVE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.post(URL, params, headers).then(response => {
      dispatch("listInitiatives", { org: params.org });
    });
  },

  // DELETE
  deleteInitiative({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).DELETE_INITIATIVE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.delete(URL, headers).then(response => {
      dispatch("listInitiatives", { org: params.org });
    });
  },
  // PATCH
  publishInitiative({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).PUBLISH_INITIATIVE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .patch(URL, {}, headers)
        .then(response => {
          dispatch("getInitiativeDetail", params);
          dispatch("listInitiatives", { org: params.org });
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },
  unpublishInitiative({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).UNPUBLISH_INITIATIVE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .patch(URL, {}, headers)
        .then(response => {
          dispatch("getInitiativeDetail", params);
          dispatch("listInitiatives", { org: params.org });
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },
  publishSpace({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).PUBLISH_SPACE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .patch(URL, {}, headers)
        .then(response => {
          dispatch("getSpacesList", params);
          dispatch("listAllOrganisation", { org: params.org });
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },
  unpublishSpace({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).UNPUBLISH_SPACE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.patch(URL, {}, headers).then(response => {
      dispatch("getSpacesList", params);
      dispatch("listAllOrganisation", { org: params.org });
    });
  }
};
