export default {
  userInfo: state => state.userInfo,
  isLoading: state => state.isLoading,
  partnerLogo: state => state.partnerLogo,
  userAccountInfo: state => state.userAccountInfo,
  latestCourses: state => state.latestCourses,
  currentUser: state => state.currentUser,
  isFetchingCourses: state => state.isFetchingCourses,
  isPartnerApiIntegrated: state => state.isPartnerApiIntegrated
};
