export default {
  SET_CONFIG(state, config) {
    state.config = config;
  },
  SET_KEYCLOAK_USER_PROFILE(state, userProfile) {
    state.userProfile = userProfile;
  },
  SET_ERRORS(state, error) {
    state.error = error;
  },
  SET_SPACES_LIST(state, spacesList) {
    state.spacesList = spacesList;
  },
  SET_ORG_LIST(state, orgList) {
    state.orgList = orgList;
  },
  SET_INITIATIVES_LIST(state, initiativesList) {
    state.initiativesList = initiativesList;
  },
  SET_INITIATIVE_DETAIL(state, initiativeDetail) {
    state.initiativeDetail = initiativeDetail;
  },
  SET_FEATURED_RESOURCES_LIST(state, featuredResourcesList) {
    state.featuredResourcesList = featuredResourcesList;
  },
  SET_MANAGE_SECTION_LIST(state, manageSectionList) {
    state.manageSectionList = manageSectionList;
  },
  SET_TAGS_LIST(state, tags) {
    state.tags = tags;
  }
};
