import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    redirect: "/partner"
  },
  {
    path: "/partner",
    component: () => import("../views/Partner.vue"),
    meta: {
      title: "Home"
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../components/Partner/Home.vue"),
        meta: {
          title: "Home"
        },
        alias: "home"
      },
      {
        path: "courses",
        component: () => import("../components/Partner/Courses.vue"),
        children: [
          {
            path: "",
            name: "CourseList",
            alias: "list",
            meta: {
              title: "Courses"
            },
            component: () =>
              import("../components/Partner/Courses/ListCourses.vue")
          },
          {
            path: "api-get-started",
            name: "ApiGetStarted",
            meta: {
              title: "Courses"
            },
            component: () =>
              import("../components/Partner/CourseApiList/GetStarted.vue")
          },
          {
            path: "list-a-course",
            name: "ManualCourseListing",
            meta: {
              title: "Courses"
            },
            component: () =>
              import("../components/Partner/Courses/ListingManualCourse.vue")
          }
        ]
      },
      {
        path: "learning-space",
        name: "LearningSpace",
        meta: {
          title: "Learning Space"
        },
        component: () => import("../components/Partner/LearningSpace.vue")
      },
      {
        path: "learning-space/:org/edit",
        name: "EditPartnerLearningSpace",
        component: () =>
          import("../components/LearningSpace/EditLearningSpace.vue"),
        meta: {
          title: "Learning Space"
        }
      },
      {
        path: "settings",
        name: "Settings",
        meta: {
          title: "Settings"
        },
        component: () => import("../components/Partner/Settings.vue")
      },
      {
        path: "user-permissions",
        component: () => import("../components/Partner/UserPermissions.vue"),
        children: [
          {
            path: "",
            name: "UserList",
            alias: "list",
            meta: {
              title: "User Permissions - List"
            },
            component: () =>
              import("../components/Partner/UserPermissions/UserList.vue")
          },
          {
            path: ":id/edit",
            name: "EditingUser",
            meta: {
              title: "Editing User"
            },
            component: () =>
              import("../components/Partner/UserPermissions/Edit.vue")
          }
        ]
      },
      {
        path: "course-api-list",
        component: () => import("../components/Partner/CourseApiList.vue"),
        children: [
          {
            path: "",
            name: "Intro",
            alias: "intro",
            meta: {
              title: "Get Started"
            },
            component: () =>
              import("../components/Partner/CourseApiList/GetStarted.vue")
          },
          {
            path: "request",
            name: "ApiRequest",
            meta: {
              title: "API Request"
            },
            component: () =>
              import("../components/Partner/CourseApiList/ApiRequest.vue")
          }
        ]
      },
      {
        path: "onboarding",
        name: "Onboarding",
        meta: {
          title: "Onboarding"
        },
        component: () => import("../components/Partner/Onboarding.vue")
      }
    ]
  },

  {
    path: "/learning-space",
    name: "LearningSpacesHome",
    component: () => import("../views/LearningSpace.vue"),
    meta: {
      title: "Home"
    },
    children: [
      {
        path: ":org/edit",
        name: "EditLearningSpace",
        component: () =>
          import("../components/LearningSpace/EditLearningSpace.vue"),
        meta: {
          title: "Edit"
        }
      },
      {
        path: ":org/initiative/:id/edit",
        name: "EditInitiative",
        component: () =>
          import("../components/LearningSpace/EditLearningSpace.vue"),
        meta: {
          title: "Edit Initiative"
        }
      },

      {
        path: ":org/",
        name: "SelectedLearningSpace",
        component: () =>
          import("../components/LearningSpace/SelectedLearningSpace.vue"),
        meta: {
          title: "Selected Learning Space"
        }
      },

      {
        path: ":org/create-initiative",
        name: "CreateInitiative",
        component: () =>
          import("../components/LearningSpace/CreateInitiative.vue"),
        meta: {
          title: "Create Initiative"
        }
      }
    ]
  },
  {
    path: "/unauthorised-access",
    name: "UnauthorisedAccess",
    component: () => import("../views/Unauthorised.vue"),
    meta: {
      title: "Unauthorised Access"
    }
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/ErrorPage.vue"),
    meta: {
      title: "Error"
    }
  },
  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
    meta: {
      title: "Page Not Found"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  document.title = "Learning Spaces";
  if (nearestWithTitle) {
    document.title = document.title + " - " + nearestWithTitle.meta.title;
  }
  if (to.path.includes("partner") && store.getters.userInfo === null) {
    store.commit("SET_LOADER", true);
    setTimeout(() => {
      store
        .dispatch("getUserSummary")
        .then(() => {
          let userInfo = store.getters.userInfo;
          if (userInfo.show_onboarding && userInfo.user.role === "admin") {
            next({ name: "Onboarding" });
          } else {
            next();
          }
        })
        .catch(err => {
          if (err.response) {
            next({ name: "LearningSpacesHome" });
          }
        });
    }, 0);
  } else {
    next();
  }
});

router.afterEach(() => {
  store.commit("SET_LOADER", false);
});
export default router;
