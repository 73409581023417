import axios from "axios";

axios.defaults.headers.common["Accept-Language"] = "en";

const getAuthHeader = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

function getURL(baseURL, params = {}) {
  return {
    // GET
    GET_USER_SUMMARY: `${baseURL}/gateway/loggedin-user/summary/`,
    GET_USER_ACCOUNT_INFO: `${baseURL}/gateway/users/me/`,
    LIST_LATEST_COURSES: `${baseURL}/gateway/partner-courses/?page_size=${params.pageSize}&page=1&ordering=-updated_at`,
    CREATE_USER: `${baseURL}/gateway/users/`,
    GET_USER_DETAILS: `${baseURL}/gateway/users/${params.id}/`,
    GET_USAGE_STATISTICS: `${baseURL}/gateway/usage/statistics/`,
    GET_PARTNER_INTEGRATION_STATUS: `${baseURL}/gateway/partner-exists/`,

    // POST
    CREATE_PARTNER_COURSES: `${baseURL}/gateway/partner-api-information/`,
    CREATE_MANUAL_COURSE: `${baseURL}/gateway/course-overview/`,

    // PUT
    UPDATE_PARTNER_LOGO: `${baseURL}/gateway/partner-logo/`,
    RESET_PASSWORD: `${baseURL}/gateway/users/me/reset-password/`,

    // DELETE
    DELETE_MY_ACCOUNT: `${baseURL}/gateway/users/me/delete-account/`
  };
}

export default {
  // GET
  getUserSummary({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).GET_USER_SUMMARY;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .get(URL, headers)
        .then(response => {
          commit("SET_USER_INFO", response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPartnerLogo({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).UPDATE_PARTNER_LOGO;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      commit("SET_PARTNER_LOGO", response.data.logo_url);
    });
  },
  getUserAccountInfo({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).GET_USER_ACCOUNT_INFO;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      commit("SET_USER_ACCOUNT_INFO", response.data);
    });
  },
  getUserDetails({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_USER_DETAILS;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      commit("SET_CURRENT_USER", response.data);
    });
  },
  listLatestCourses({ rootState, commit }, params) {
    const URL = getURL(rootState.config.baseURL, params).LIST_LATEST_COURSES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      commit("SET_LATEST_COURSES", response.data);
    });
  },
  getUsageStatistics({ rootState }) {
    const URL = getURL(rootState.config.baseURL).GET_USAGE_STATISTICS;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .get(URL, headers)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },
  getPartnerIntegrationStatus({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).GET_PARTNER_INTEGRATION_STATUS;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.get(URL, headers).then(response => {
      commit("SET_PARTNER_INTEGRATION_STATUS", response.data.data);
    });
  },

  // POST
  createUser({ rootState }, params) {
    const URL = getURL(rootState.config.baseURL).CREATE_USER;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .post(URL, params, headers)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },
  createPartnerCourses({ rootState }, params) {
    const URL = getURL(rootState.config.baseURL).CREATE_PARTNER_COURSES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .post(URL, params, headers)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },
  createCourseManually({ rootState }, params) {
    const URL = getURL(rootState.config.baseURL).CREATE_MANUAL_COURSE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .post(URL, params, headers)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },

  // PATCH
  updateUserDetails({ rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_USER_DETAILS;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .patch(URL, params, headers)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },

  // PUT
  updatePartnerLogo({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL).UPDATE_PARTNER_LOGO;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.put(URL, params, headers).then(response => {
      commit("SET_PARTNER_LOGO", response.data.logo_url);
    });
  },
  updateUserAccountInfo({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL).GET_USER_ACCOUNT_INFO;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise(resolve => {
      axios.put(URL, params, headers).then(response => {
        commit("SET_USER_ACCOUNT_INFO", response.data);
        resolve();
      });
    });
  },
  resetPassword({ rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).RESET_PASSWORD;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .put(URL, params, headers)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },

  // DELETE
  deleteMyAccount({ rootState }) {
    const URL = getURL(rootState.config.baseURL).DELETE_MY_ACCOUNT;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.delete(URL, headers);
  }
};
